$break-xs:1199px;
$max-width:1199px;

* {
    box-sizing: border-box;
    font-family: Helvetica;
    outline-width: 0;
}


body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    color: #606060;
    overflow-x: hidden;
}

body {
    @media screen and (max-width: $break-xs) {
        padding-top: 73px;
    }
}

img {
    display: block;
    width: 100%;
    height: auto;
}

p {
    padding: 0
}


a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
}

.flex {
    display: flex;
}

.flex-end {
    display: flex;
    align-items: flex-end;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-v-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.pc-show {

    display: block;

    @media screen and (max-width: $break-xs) {
        display: none;
    }
}

.mobile-show {
    display: none;

    @media screen and (max-width: $break-xs) {
        display: block;
    }
}

.container {
    width: 100%;
    max-width: $max-width;
    margin: auto;

    @media screen and (max-width: $break-xs) {
        // width: calc(100% - 32px);
        width: 100%;
        padding: 0 16px;
    }
}

.title1 {
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $break-xs) {
        font-size: 40px;
        line-height: 48px;
        text-align: left;
    }
}



.title2 {
    font-size: 107px;
    font-weight: bold;
    letter-spacing: 5.35px;
    line-height: 100px;
    @media screen and (max-width: $break-xs) {
        font-size: 67px;
        line-height: 67px;
    }
}

.title3 {
    font-size: 20px;
    font-weight: bold;

    @media screen and (max-width: $break-xs) {
        font-size: 12px;
        line-height: 12px;
    }
}

.title4 {
    font-size: 60px;
    line-height: 65px;
    font-weight: bold;

    @media screen and (max-width: $break-xs) {
        font-size: 40px;
        line-height: 48px;
    }
}

.content1 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.9px;

    @media screen and (max-width: $break-xs) {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.72px;
    }
}

.content2 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.72px;
}

.content3 {
    font-size: 10px;
    letter-spacing: 0;
}

.content4 {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.9px;
}

.sub-content1 {
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.54px;
    color: #070606;
}




.row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $break-xs) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.column2 {
    width: calc((100% - 56px)/2);

    @media screen and (max-width: $break-xs) {
        width:100%
    }
}

.green {
    color: #008113;
}

.white {
    color: #fff;
}

.black {
    color: #000
}