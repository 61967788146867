$break-xs:1199px;
$max-width:1199px;

.header-wrap-bg {
    position: fixed;
    height: 169px;
    width: 100%;
    top: 65px;
    z-index: 10;

    .container {
        height: 100%;
        // background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
}


.header-wrap {
    height: 72px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background: #ffff;
    box-shadow: 0px 3px 10px #0000004D;

    @media screen and (max-width: $break-xs) {
        height: 76px;
        box-shadow: 0px 3px 6px #005D0E65;
        background: #fff;
    }

    .menu-list {
        width: 144px;
        float: right;


        .menu-item {
            height: 35px;
            font-size: 16px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .menu-item-bg1 {
            background: #86D150
        }

        .menu-item-bg2 {
            background: #71C937
        }

        .menu-item-bg3 {
            background: #60C01D
        }

        .menu-item-bg4 {
            background: #4CB304
        }

        .menu-item-bg5 {
            background: #3E9B00
        }

    }

    .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        height: 100%;
        padding: 0 16px 20px;
        align-items: flex-end;

        @media screen and (max-width: $break-xs) {
            padding: 16px;
        }

        .logo {
            width: 175px;

            @media screen and (max-width: $break-xs) {
                height: 100%;
                width: auto;
            }

            img {
                @media screen and (max-width: $break-xs) {
                    height: 100%;
                    width: auto;
                }
            }

        }

        .menu-logo {
            font-size: 23px;
            cursor: pointer;
        }

        .links {
            display: flex;

            .link {
                font-size: 18px;
                color: #06713A;
                padding-left: 26px;
                cursor: pointer;
            }

            @media screen and (max-width: $break-xs) {
                display: none;
            }

        }

        .header-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;

            @media screen and (max-width: $break-xs) {
                justify-content: flex-end;
            }

            .lang {
                width: 88px;
                height: 27px;
                display: flex;
                margin-top: 6px;
                font-size: 12px;
                padding: 0 17px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                justify-content: space-around;
                align-items: center;
                background: #53A1CB 0% 0% no-repeat padding-box;

                @media screen and (max-width: $break-xs) {
                    display: none;
                }
            }
        }



    }



}

.banner {
    background: transparent linear-gradient(90deg, #FFFFFF 0%, #EDF7FC 100%) 0% 0% no-repeat padding-box;
    background-size: 100% 65%;
    max-width: 1200px;
    margin: auto;

    @media screen and (max-width: $break-xs) {
        padding-top: 0;
        width: 100%;
        background-size: 100% 82%;
    }

    .banner-img {
        width: 100%;

        @media screen and (max-width: $break-xs) {
            height: unset;
            margin: auto;
        }

        img {
            width: 100%;
            height: auto;

            @media screen and (max-width: $break-xs) {
                width: 100%;
                content: url('../img/home-banner-s.png');
            }

        }
    }
}

.about-us {
    margin-bottom: 140px;
    padding: 0 16px;

    @media screen and (max-width: $break-xs) {
        padding-bottom: 0;
        margin-bottom: 32px;
    }

    .title {
        width: 39%;
        margin-bottom: 64px;

        @media screen and (max-width: $break-xs) {
            margin-bottom: 15px;
            width: 57%;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('../img/title-1-s.svg')
            }
        }
    }

    .about-us-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 140px;

        @media screen and (max-width: $break-xs) {
            flex-direction: column;
            margin-bottom: 32px;
        }

        .about-us-left {
            width: 40%;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: $break-xs) {
                width: 100%;
                justify-content: center;
            }

            .image {
                width: 71.4%;

                @media screen and (max-width: $break-xs) {
                    width: 65%;
                }
            }
        }

        .about-us-right {
            width: 60%;
            padding-left: 10%;

            @media screen and (max-width: $break-xs) {
                width: 100%;
                padding-left: 0;
            }

            .content1 {
                margin-bottom: 46px;

                @media screen and (max-width: $break-xs) {
                    margin-bottom: 24px;
                }
            }

            p {
                margin: 0.5rem 0;
            }
        }

        .image {
            @media screen and (max-width: $break-xs) {
                margin-bottom: 12px;
            }
        }

    }


}

.microbiome {
    width: 973px;
    margin-bottom: 90px;

    @media screen and (max-width: $break-xs) {
        width: 100%;
        box-shadow: 0px 8px 10px #005D0E33;
        margin-bottom: 0px;
        padding-bottom: 60px;
    }

    .title {
        margin-bottom: 90px;

        @media screen and (max-width: $break-xs) {
            margin-bottom: -14px;
        }
    }

    .col {
        width: 200px;

        @media screen and (max-width: $break-xs) {
            width: 100%;
        }

        img {
            margin-bottom: 64px;

            @media screen and (max-width: $break-xs) {
                width: 57.14%;
                margin: 60px auto 32px;
            }


        }
    }
}

.lactoferrin {

    max-width: 1200px;
    margin: auto;

    &.container {
        @media screen and (max-width: $break-xs) {
            padding: 0;
        }
    }

    .lactoferrin-bg {
        background: rgba(106, 194, 44, 0.08);
        position: relative;
        padding: 47px 0 0 16px;

        @media screen and (max-width: $break-xs) {
            height: unset;
        }


    }

    .lactoferrin-bg-2 {
        height: 80px;
        float: right;
        width: calc(100% - 130px - 16px);
        background: rgba(106, 194, 44, 0.08);
        padding: 0 0 0 16px;

        @media screen and (max-width: $break-xs) {
            width: calc((100% - 16px)*0.84);
            height: 40px;
        }
    }

    p {
        margin: 0.5rem 0;
    }

    .title {
        width: 71.5%;

        @media screen and (max-width: $break-xs) {
            width: 100%;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('../img/title-2-s.svg')
            }
        }
    }

    .content {
        width: calc(100% - 130px);
        margin-left: 130px;
        background: rgba(106, 194, 44, 0.08);
        padding: 80px 257px 80px 116px;


        @media screen and (max-width: $break-xs) {
            padding: 27px 16px;
            margin-left: 0;
            background: transparent;
            width: 100%;
            position: relative;
        }

        .lactoferrin-bg-1 {

            @media screen and (max-width: $break-xs) {
                height: 100%;
                width: 84%;
                position: absolute;
                top: 0;
                right: 0;
                background: rgba(106, 194, 44, 0.08);
            }

        }
    }

    .features {
        background: #fff;
        opacity: 1;
        width: 100%;
        max-width: 1200px;
        margin: 54px auto 91px;
        padding: 50px 0 185px;
        position: relative;
        z-index: -1;

        @media screen and (max-width: $break-xs) {
            background: #fff;
            padding: 0 0 30px;
            margin: 0 auto;
            padding: 50px 0 47px;
        }

        .group-circles {
            @media screen and (max-width: $break-xs) {
                width: 375px;
                margin: auto;
                height: 410px;
                position: relative;
            }
        }

        .image {
            width: 71%;
            margin: auto;
            // background: rgba(6, 113, 58, 0.1);

            @media screen and (max-width: $break-xs) {
                width: 100%;
            }

        }


        .circle-content {
            position: absolute;
            width: 21.67%;
            border-radius: 200px;
            text-align: center;

            // @media screen and (max-width: $break-xs) {
            //     position: relative;
            // }

            .feature {
                text-align: left;
                padding-left: 35px;
                width: 90%;
            }
        }

        .circle-content:before {
            content: "";
            float: left;
            padding-top: 100%;
        }

        .circle-bg1-enter {
            background: #7DC54A;
            width: 21.67%;
            top: 90px;
            left: 0;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 0;
                left: -84px;
                bottom: 282px;
            }
        }

        .circle-bg1-enter-active {
            background: #7DC54A;
            width: 21.67%;
            top: 90px;
            left: 0;
            transform: translateX(69px);
            transition: transform 700ms;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 0;
                left: -84px;
                transform: translateX(100px);
                transition: transform 700ms;
                bottom: 282px;
            }
        }

        .circle-bg1-enter-done {
            background: #7DC54A;
            width: 21.67%;
            top: 90px;
            left: 69px;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 0;
                left: 16px;
                bottom: 282px;
            }

        }

        .circle-title {
            font-style: oblique;
            padding-top: 20px;
        }


        .circle-bg2-enter {
            background: #7ED342;
            width: 21.67%;
            bottom: 86px;
            left: 0;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 75px;
                right: -86px;
                left: unset;
                bottom: 188px;
            }
        }

        .circle-bg2-enter-active {
            background: #7ED342;
            width: 21.67%;
            bottom: 86px;
            left: 0;
            transform: translateX(115px);
            transition: transform 700ms;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 75px;
                right: -86px;
                left: unset;
                bottom: 188px;
                transform: translateX(-100px);
                transition: transform 700ms;
            }
        }

        .circle-bg2-enter-done {
            background: #7ED342;
            width: 21.67%;
            bottom: 86px;
            left: 115px;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 75px;
                right: 16px;
                left: unset;
                bottom: 188px;
            }
        }

        .circle-bg3-enter {
            background: #5ABA16;
            width: 21.67%;
            top: 67px;
            right: 0;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 180px;
                left: -100px;
                // left: 26px;
                right: unset;
                bottom: 94px;
            }
        }

        .circle-bg3-enter-active {
            background: #5ABA16;
            width: 21.67%;
            top: 67px;
            right: 0;
            transform: translateX(-66px);
            transition: transform 700ms;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 180px;
                left: -100;
                right: unset;
                bottom: 94px;
                transform: translateX(126px);
                transition: transform 700ms;
            }
        }

        .circle-bg3-enter-done {
            background: #5ABA16;
            width: 21.67%;
            top: 67px;
            right: 66px;

            @media screen and (max-width: $break-xs) {
                width: 166px;
                height: 166px;
                top: 180px;
                left: 26px;
                right: unset;
                bottom: 94px;
            }
        }

        .circle-bg4-enter {
            background: #83E041;
            width: 17.5%;
            bottom: 124px;
            right: 0;

            @media screen and (max-width: $break-xs) {
                width: 140px;
                height: 140px;
                top: 272px;
                bottom: unset;
                right: -70px;
            }
        }

        .circle-bg4-enter-active {
            background: #83E041;
            width: 17.5%;
            bottom: 124px;
            right: 0;
            transform: translateX(-109px);
            transition: transform 700ms;

            @media screen and (max-width: $break-xs) {
                width: 140px;
                height: 140px;
                top: 272px;
                bottom: unset;
                right: -70px;
                transform: translateX(-100px);
                transition: transform 700ms;
            }
        }

        .circle-bg4-enter-done {
            background: #83E041;
            width: 17.5%;
            bottom: 124px;
            right: 109px;

            @media screen and (max-width: $break-xs) {
                width: 140px;
                height: 140px;
                top: 272px;
                bottom: unset;
                right: 30px;
            }
        }

    }
}

.for-who {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto 47px;
    padding: 0 16px;
    position: relative;



    .title {
        width: 682px;

        @media screen and (max-width: $break-xs) {
            width: 83%;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('../img/title-3-s.svg');
            }
        }
    }

    .subtitle {
        font-size: 23px;
        line-height: 26px;
        font-weight: bold;
        font-style: Oblique;
        color: #000000;
        letter-spacing: 1.03px;
        margin: 0 0 13px;

        @media screen and (max-width: $break-xs) {
            font-size: 18px;
        }

    }

    .content1 {
        width: 410px;

        @media screen and (max-width: $break-xs) {
            width: 100%;
        }
    }

    .for-who-img1 {
        position: absolute;
        width: 338px;
        right: 61px;
        top: 94px;

        @media screen and (max-width: $break-xs) {
            position: static;
            display: flex;
            justify-content: flex-end;
        }

        .img-block {
            @media screen and (max-width: $break-xs) {
                display: flex;
                justify-content: flex-end;
                width: 71%;
            }
        }

        img {
            @media screen and (max-width: $break-xs) {
                width: 100%;
            }
        }
    }

    .for-who-img2 {
        position: relative;
        width: 432px;
        margin: auto;
        top: -50px;
        left: -28px;

        @media screen and (max-width: $break-xs) {
            position: static;
            margin-bottom: 62px;
            display: flex;
            justify-content: flex-start;

        }

        img {
            @media screen and (max-width: $break-xs) {
                width: 63.1%;

            }
        }
    }
}

.suitable {
    margin-bottom: 82px;
    width: 100%;
    max-width: $max-width;
    margin: auto;

    .suitable-img {
        width: 57.25%;

        @media screen and (max-width: $break-xs) {
            width: 100%;
            margin-bottom: 45px;
        }
    }

    .suitable-content {
        width: 42.75%;
        padding: 0 16px 0 69px;

        @media screen and (max-width: $break-xs) {
            width: 100%;
            padding: 0 16px 24px;
            box-shadow: 0px 8px 10px #005D0E33;
        }

        .subtitle {
            font-size: 23px;
            line-height: 26px;
            font-weight: bold;
            font-style: Oblique;
            color: #000000;
            margin: 18px 0 6px;

            @media screen and (max-width: $break-xs) {
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.81px;
                margin: 6px 0 18px;
            }
        }

    }
}

.safe-preparation {

    padding: 0 16px;

    .title {
        width: 1129px;

        @media screen and (max-width: $break-xs) {
            width: 100%;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('../img/title-4-s.svg')
            }
        }
    }

    .steps {
        margin: 52px 0;


        .step-block {
            display: flex;
            margin-bottom: 52px;
            justify-content: center;

            @media screen and (max-width: $break-xs) {
                justify-content: space-between;
                margin-bottom: 40px;
            }

            .image {
                width: 170px;
                margin-right: 26px;

                @media screen and (max-width: $break-xs) {
                    width: 22.5%;
                    margin-right: 0;
                }
            }

            .content {
                padding-top: 36px;

                @media screen and (max-width: $break-xs) {
                    width: 73.7%;
                    padding-top: 0;
                }

                .step-title {
                    color: #7DC54A;
                    text-transform: uppercase;
                    font-size: 30px;
                    font-weight: bold;
                    line-height: 26px;
                    letter-spacing: 1.35px;
                    margin-bottom: 14px;

                    @media screen and (max-width: $break-xs) {
                        font-size: 20px;
                        letter-spacing: 0.9px;
                        margin-bottom: 10px;
                    }
                }

                .content1 {
                    width: 774px;

                    @media screen and (max-width: $break-xs) {
                        width: 100%;
                    }
                }
            }


        }
    }
}


.contact-us {

    padding: 0 16px;
    margin-bottom: 36px;

    .title {
        width: 639px;

        @media screen and (max-width: $break-xs) {
            width: 83%;
        }

        img {
            @media screen and (max-width: $break-xs) {
                content: url('../img/title-5-s.svg')
            }
        }
    }

    .form-block {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $break-xs) {
            flex-direction: column;
        }

        .left {
            padding-top: 43px;

            @media screen and (max-width: $break-xs) {
                width: 100%;
                padding-top: 9px;
            }

            .email {
                padding-top: 22px;

                @media screen and (max-width: $break-xs) {
                    width: 70.4%;
                }
            }

            .form {
                width: 630px;
                margin-top: 22px;

                @media screen and (max-width: $break-xs) {
                    width: 100%;
                }

                input,
                textarea {
                    width: 100%;
                    height: 54px;
                    margin-bottom: 20px;
                    border: 1px solid #7DC74A;
                    padding: 0 20px;
                    color: #7DC74A;
                    font-size: 16px;

                    @media screen and (max-width: $break-xs) {
                        font-size: 16px;
                    }

                    &::placeholder {
                        color: #7DC74A;
                        font-size: 16px;

                        @media screen and (max-width: $break-xs) {
                            font-size: 16px;
                        }
                    }
                }

                textarea {
                    height: 178px;
                    padding: 18px 20px 0;
                }

                .submit-block {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 36px;

                    @media screen and (max-width: $break-xs) {
                        margin-top: 20px;
                    }
                }

                .submit {
                    cursor: pointer;
                    display: flex;
                    background: #7DC74A 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    width: 191px;
                    height: 54px;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    font-size: 20px;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: $break-xs) {
                        width: 100%;
                    }
                }
            }
        }

        .right {
            .image {
                width: 480px;

                @media screen and (max-width: $break-xs) {
                    display: none;
                }
            }
        }
    }
}

.contact {
    padding: 44px 6.2% 32px;
    background: #ECF7FC 0% 0% no-repeat padding-box;

    @media screen and (max-width: $break-xs) {
        padding: 28px 0 28px 16px;
    }

    .title1 {
        text-align: left;
        margin-bottom: 23px;

        @media screen and (max-width: $break-xs) {
            margin-bottom: 16px;
        }
    }

    span {
        padding-left: 13px;
        color: #009DFF;
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    background: #002B15 0% 0% no-repeat padding-box;
    box-shadow: 0px -10px 10px #005D0E33;
}